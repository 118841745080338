import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-customer-signature-geo-location',
  templateUrl: './customer-signature-geo-location.component.html',
  styleUrls: ['./customer-signature-geo-location.component.scss']
})
export class CustomerSignatureGeoLocationComponent implements OnInit {

  public locationLatitude: number = 0;
  public locationLongitude: number = 0;
  public distance: number = 0;
  public targetLocation: string = '';


  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    // Acessando os parâmetros passados via state
    const state = history.state;
    if (state) {
      this.locationLatitude = state.locationLatitude;
      this.locationLongitude = state.locationLongitude;
      this.distance = state.distance;
      this.targetLocation = state.targetLocation;
      console.log('geolocation: ' + this.locationLatitude, this.locationLongitude);
      console.log('targetLocation: ' + this.targetLocation);
      console.log('distance: ' + this.distance.toFixed(2));
    } else {
      // Tratar caso em que não há dados no estado
      console.log('Nenhum dado passado via state.');
      this.distance = 0;
    }
  }

}
