import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import {CustomerSignatureComponent} from "./pages/customer-signature/customer-signature.component";
import {CustomerSignatureInfoValidationComponent} from "./pages/customer-signature/customer-signature-info-validation/customer-signature-info-validation.component";
// import {CustomerSignatureContractSignComponent} from "./pages/customer-signature/customer-signature-contract-sign/customer-signature-contract-sign.component";
import {CustomerSignatureModule} from "./pages/customer-signature/customer-signature.module";
import {CustomerSignatureFinishedComponent} from "./pages/customer-signature/customer-signature-finished/customer-signature-finished.component";
import {CustomerSignatureContractListComponent} from "./pages/customer-signature/customer-signature-contract-list/customer-signature-contract-list.component";
import {CustomerSignatureDocDetailsComponent} from "./pages/customer-signature/customer-signature-doc-details/customer-signature-doc-details.component";
import {CustomerSignatureComponent} from "./pages/customer-signature/customer-signature.component";
import {
  CustomerSignatureGeoLocationComponent
} from "./pages/customer-signature/customer-signature-geo-location/customer-signature-geo-location.component";

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'start-sign',
  //   pathMatch: 'full',
  // },
  // {
    // path: 'start-sign/:guid/:key/:cpf',
    // component: CustomerSignatureInfoValidationComponent
  // },
  {
    path: '',
    component: CustomerSignatureComponent
  },
  {
    path: ':uniqueKey/:token',
    component: CustomerSignatureInfoValidationComponent
  },
  {
    path: 'sign-list/:guid/:key/:cpf/:uniqueKey',
    component: CustomerSignatureContractListComponent
  },
  {
    path: 'sign-list/:guid/:key/:cpf/:uniqueKey/:token',
    component: CustomerSignatureContractListComponent
  },
  {
    path: 'start-sign/:guid/:key/:cpf/:token',
    component: CustomerSignatureInfoValidationComponent
  },
  {
    path: 'start-sign/:guid/:key',
    component: CustomerSignatureInfoValidationComponent
  },
  {
    path: 'document-details/:guid/:key/:cpf/:uniqueKey',
    component: CustomerSignatureDocDetailsComponent
  },
  {
    path: 'end-sign',
    component: CustomerSignatureFinishedComponent
  },
  {
    path: 'geo-location-error',
    component: CustomerSignatureGeoLocationComponent
  }
];

@NgModule({
  imports: [
    CustomerSignatureModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
